import './../../../StylesProduct/configuration.scss'

const Configuration = ({ProductBracketSmart}) => {

    return(
        <ul className='configuration'>
            <li>
                {ProductBracketSmart[0].package_contents}
            </li>
        </ul>
    )
}

export default Configuration;