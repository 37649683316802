import './../../../StylesProduct/specifications.scss'

const Specifications = ({ProductBracketSmart}) => {
    console.log(ProductBracketSmart)
    const { 
            material,
            product_color,
            weight_in_packaging_g,
            width_in_packaging_mm,
            height_in_packaging_mm,
            length_in_packaging_mm
          } = ProductBracketSmart[0]
 
    return(
        <div className="specifications">
            <h2>Характеристики</h2>
            <ul>
                <li>
                    <span>Единиц в одном товаре</span>
                    <span>1 шт.</span>
                </li>
                <li>
                    <span>Длина </span>
                    <span>{width_in_packaging_mm} мм</span>
                </li>
                <li>
                    <span>Ширина</span>
                    <span>{height_in_packaging_mm} мм</span>
                </li>
                <li>
                    <span>Высота</span>
                    <span>{length_in_packaging_mm} мм</span>
                </li>
                <li>
                    <span>Вес</span>
                    <span>{weight_in_packaging_g} г</span>
                </li>
                <li>
                    <span>Материал</span>
                    <span>{material}</span>
                </li>
                <li>
                    <span>Цвет</span>
                    <span>{product_color}</span>
                </li> 
            </ul>
        </div>
    )
}

export default Specifications;